<template>
  <div class="form-group">
    <input
      :value="value"
      :type="inputType"
      class="form-control"
      :disabled="disabled"
      @input="emitInput"
      @change="emitChange"
    >
    <label>
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    typeNumber: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    typePassword: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputType() {
      if (this.typeNumber) return 'number';
      if (this.typePassword) return 'password';
      return 'text';
    },
  },
  methods: {
    emitChange(val) {
      this.$emit('change', val.target.value);
    },
    emitInput(val) {
      this.$emit('input', val.target.value);
    },
  },
};
</script>

<style>

</style>
