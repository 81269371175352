/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    cities: {},
  },
  mutations: {
    SET_CITIES(state, data) {
      state.cities = data;
    },
  },
  actions: {
    getCities({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/cities'.paginableUrl(params), {
          params: {
            paginate: true,
          },
        })
          .then((res) => {
            commit('SET_CITIES', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_CITIES', {});
            reject(err);
          });
      });
    },
    createCity({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/cities', data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateCity({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/cities/${data.id}`, data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteCity({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/cities/${data.id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    cities(state) {
      return state.cities;
    },
  },
};
