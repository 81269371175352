/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    countries: {},
  },
  mutations: {
    SET_COUNTRIES(state, data) {
      state.countries = data;
    },
  },
  actions: {
    getCountries({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get(`${'/countries'.paginableUrl(params)}&paginate=true`)
          .then((res) => {
            commit('SET_COUNTRIES', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_COUNTRIES', {});
            reject(err);
          });
      });
    },
    updateCountry({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/countries/${data.id}`, data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    countries(state) {
      return state.countries;
    },
  },
};
