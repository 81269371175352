<template>
  <div
    :class="{
      'max-600': !requestSent
    }"
  >
    <div class="row overflow-hidden">
      <BudgetFilters
        :from-home="!requestSent"
        :class="{
          'col-md-3': requestSent,
          'col-md-12': !requestSent
        }"
        @onFinish="getResults"
      />

      <div
        v-if="requestSent"
        class="col-md-9"
      >
        <div
          v-if="resultsLoaded && budgetResults"
          key="main"
        >
          <academies
            v-model="selCourse"
          />
          <accommodations
            v-if="lastSearch.budgetData.accommodation_type !== 'none' && selCourse"
            v-model="selAccommodation"
            :course="selCourse"
          />
          <totals
            :course="selCourse"
            :accommodation="selAccommodation"
            :last-search="lastSearch"
            @onSendBudget="sendBudget"
          />
        </div>

        <div
          v-else-if="!budgetResults"
          key="notfound"
          class="card"
        >
          <div class="card-body text-center">
            <small v-if="!nuevaBusquedaMsg">
              No encontramos resultados con estos filtros, prueba con otros.
            </small>
            <small v-else>{{ nuevaBusquedaMsg }}</small>
          </div>
        </div>

        <Loader
          v-else
          key="loader"
        />
      </div>
    </div>

    <FullPageLoader v-if="sendingBudget" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import BudgetFilters from '@/components/BudgetFilters.vue';
import Loader from '@/components/Loader.vue';
import FullPageLoader from '@/components/FullPageLoader.vue';
import academies from './academies.vue';
import accommodations from './accommodations.vue';
import totals from './totals.vue';

export default {
  components: {
    Loader,
    BudgetFilters,
    academies,
    accommodations,
    totals,
    FullPageLoader,
  },
  data: () => ({
    budget: null,
    selCourse: null,
    requestSent: false,
    selAccommodation: null,
    nuevaBusquedaMsg: null,
    lastSearch: {},
    sendingBudget: false,
  }),
  computed: {
    ...mapGetters([
      'budgetResults',
      'budgetData',
      'resultsLoaded',
    ]),
  },
  watch: {
    // eslint-disable-next-line func-names
    'budgetData.accommodation_type': function () {
      if (!this.budgetData.accommodation_weeks) {
        this.budgetData.accommodation_weeks = this.budgetData.course_weeks;
      }
    },
    selCourse() {
      this.selAccommodation = null;
    },
  },
  methods: {
    ...mapMutations([
      'SET_BUDGET_RESULTS',
      'SET_BUDGET_DATA',
    ]),
    ...mapActions([
      'getBudgetResults',
      'createBudget',
    ]),
    getResults(selOrder) {
      this.requestSent = true;
      this.selCourse = null;
      this.selAccommodation = null;
      const [sortBy, sortDir] = selOrder.split(';');
      this.nuevaBusquedaMsg = null;

      this.lastSearch = {
        budgetData: { ...this.budgetData },
      };

      this.getBudgetResults({
        sortBy,
        sortDir,
      });
    },
    sendBudget() {
      this.sendingBudget = true;
      this.createBudget({
        budgetData: this.budgetData,
        course: this.selCourse,
        accommodation: this.selAccommodation,
      })
        .then(() => {
          this.$noty.success('Te hemos enviado el presupuesto a tu email, muchas gracias!');
        })
        .catch(this.showError)
        .finally(() => {
          this.sendingBudget = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.max-600 {
  max-width: 600px;
  display: block;
  margin: auto;
}

.sideFilters {
  position: absolute;
}
.orderCard {
  @media(max-width: 768px) {
    margin-top: 1em;
  }
}

</style>
