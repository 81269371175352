/* eslint-disable no-unused-vars */
export default {
  state: {
    course_types: {},
    course_type: null,
  },
  mutations: {
    SET_COURSE_TYPES(state, data) {
      state.course_types = data;
    },
    SET_COURSE_TYPE(state, data) {
      state.course_type = data;
    },
  },
  actions: {
    getCourseTypes({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/course_types'.paginableUrl(params))
          .then((res) => {
            commit('SET_COURSE_TYPES', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_COURSE_TYPES', {});
            reject(err);
          });
      });
    },
    createCourseType({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/course_types', data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    showCourseType({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.get(`/course_types/${data.id}`, data)
          .then((res) => {
            commit('SET_COURSE_TYPE', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_COURSE_TYPE', null);
            reject(err);
          });
      });
    },
    updateCourseType({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/course_types/${data.id}`, data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteCourseType({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/course_types/${data.id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    course_types(state) {
      return state.course_types;
    },
    course_type(state) {
      return state.course_type;
    },
  },
};
