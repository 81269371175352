import dateParser from '../utils/dateParser';

export default {
  state: {
    options: {
      nationalities: [],
      class_times: [],
      locations: [],
      courses: [],
      accommodation_types: [],
      accommodation_bathroom_types: [],
      people_per_room_options: [],
    },
    budgetData: {
      location: null,
      country_id: null,
      course_type: null,
      course_start_date: null,
      class_time: 'All',
      course_week_hours: null,
      course_weeks: null,

      accommodation_type: 'none',
      accommodation_bathroom_type: 'All',
      people_per_room: 'All',
      accommodation_weeks: null,

      with_transfer: true,

      nationality: null,
      phone: null,
      full_name: null,
      email: null,
    },
    budgetResults: null,
    resultsLoaded: false,
  },
  mutations: {
    SET_LOCATIONS(state, data) {
      state.options.locations = data;
    },
    SET_NATIONALITIES(state, data) {
      state.options.nationalities = data;
    },
    SET_COURSES(state, data) {
      state.options.courses = data;
    },
    SET_COURSE_TIMES(state, data) {
      state.options.class_times = data;

      if (data.length === 1) {
        state.budgetData.class_time = data[0].id;
      }
    },
    SET_ACCOMMODATION_TYPES(state, data) {
      state.options.accommodation_types = data;
    },
    SET_ACCOMMODATION_BATHROOM_TYPES(state, data) {
      state.options.accommodation_bathroom_types = data;
    },
    SET_ACCOMMODATION_PEOPLE_OPTS(state, data) {
      state.options.people_per_room_options = data;
    },
    SET_BUDGET_DATA(state, data) {
      state.budgetData = data;
    },
    SET_BUDGET_RESULTS(state, data) {
      state.budgetResults = data;
    },
    SET_RESULTS_LOADED(state, data) {
      state.resultsLoaded = data;
    },
  },
  actions: {
    getLocations({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/budget/options/locations')
          .then((res) => {
            commit('SET_LOCATIONS', res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getNationalities({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/countries')
          .then((res) => {
            commit('SET_NATIONALITIES', res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getCourses({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/budget/options/course_types', {
          params: {
            location: state.budgetData.location,
          },
        })
          .then((res) => {
            commit('SET_COURSES', res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getCourseTimes({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/budget/options/course_times', {
          params: {
            location: state.budgetData.location,
            course_type: state.budgetData.course_type,
          },
        })
          .then((res) => {
            commit('SET_COURSE_TIMES', res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getAccommodationTypesOptions({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/budget/options/accommodation_types', {
          params: state.budgetData.only([
            'location',
          ]),
        })
          .then((res) => {
            commit('SET_ACCOMMODATION_TYPES', res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getAccommodationBathroomTypesOptions({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/budget/options/bathroom_types', {
          params: state.budgetData.only([
            'location',
            'accommodation_type',
          ]),
        })
          .then((res) => {
            commit('SET_ACCOMMODATION_BATHROOM_TYPES', res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getAccommodationPeopleOptions({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.get('/budget/options/people_options', {
          params: state.budgetData.only([
            'location',
            'accommodation_type',
            'accommodation_bathroom_type',
          ]),
        })
          .then((res) => {
            commit('SET_ACCOMMODATION_PEOPLE_OPTS', res.data.data);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getBudgetResults({ commit, state }, { sortBy, sortDir }) {
      commit('SET_RESULTS_LOADED', false);
      return new Promise((resolve, reject) => {
        const parameters = {
          ...state.budgetData,
          sort_by: sortBy,
          sort_dir: sortDir,
        };

        // parse all dates in obj
        dateParser(parameters, 'YYYY-MM-DD');

        axios.get('/budget/results', {
          params: parameters,
        })
          .then((res) => {
            commit('SET_BUDGET_RESULTS', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_BUDGET_RESULTS', null);
            reject(err);
          })
          .finally(() => {
            commit('SET_RESULTS_LOADED', true);
          });
      });
    },

  },
  getters: {
    budgetData: (state) => state.budgetData,
    nationalities: (state) => state.options.nationalities,
    class_times: (state) => [
      { id: 'All', name: 'Todos' },
      ...state.options.class_times,
    ],
    locations: (state) => [
      { id: 'All', name: 'Todas' },
      ...state.options.locations,
    ],
    courses: (state) => [
      { id: 'All', name: 'Todos' },
      ...state.options.courses,
    ],
    accommodation_types_options: (state) => [
      { id: 'none', name: 'Sin Alojamiento' },
      { id: 'All', name: 'Todos' },
      ...state.options.accommodation_types,
    ],
    accommodation_bathroom_types_options: (state) => [
      { id: 'All', name: 'Todos' },
      ...state.options.accommodation_bathroom_types,
    ],
    people_per_room_options: (state) => [
      { id: 'All', name: 'Todos' },
      ...state.options.people_per_room_options,
    ],
    budgetResults: (state) => state.budgetResults,
    resultsLoaded: (state) => state.resultsLoaded,
  },
};
