import Vue from 'vue';
import Vuex from 'vuex';
import auth from './auth';
import academies from './academies';
import accommodation from './accommodation';
import agency from './agency';
import budgetOptions from './budget_options';
import budget from './budget';
import countries from './country';
import city from './city';
import courseType from './course_type';
import supplier from './supplier';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    budget,
    budgetOptions,
    agency,
    accommodation,
    academies,
    auth,
    countries,
    city,
    courseType,
    supplier,
  },
});
