/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    suppliers_list: [],
    suppliers: {},
    supplier: {},
  },
  mutations: {
    SET_SUPPLIERS(state, data) {
      state.suppliers = data;
    },
    SET_SUPPLIERS_LIST(state, data) {
      state.suppliers_list = data;
    },
    SET_SUPPLIER(state, data) {
      state.supplier = data;
    },
  },
  actions: {
    getSuppliers({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/suppliers'.paginableUrl(params), {
          params: {
            paginate: true,
          },
        })
          .then((res) => {
            commit('SET_SUPPLIERS', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_SUPPLIERS', {});
            reject(err);
          });
      });
    },
    getSuppliersList({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/suppliers', {
          params: {
            paginate: false,
          },
        })
          .then((res) => {
            console.log(res.data.data);
            commit('SET_SUPPLIERS_LIST', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_SUPPLIERS_LIST', {});
            reject(err);
          });
      });
    },
    getSupplier({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/suppliers/${id}`)
          .then((res) => {
            commit('SET_SUPPLIER', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_SUPPLIER', null);
            reject(err);
          });
      });
    },
    createSupplier({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/suppliers', data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateSupplier({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put(`/suppliers/${data.id}`, data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteSupplier({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.delete(`/suppliers/${data.id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    suppliers(state) {
      return state.suppliers;
    },
    suppliers_list(state) {
      return state.suppliers_list;
    },
    supplier(state) {
      return state.supplier;
    },
  },
};
