import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home/Home.vue';
import store from '../store';

// eslint-disable-next-line no-unused-vars
const auth = (to, from, next) => {
  if (store.getters.isLoggedIn) {
    next();
    return;
  }
  next({ name: 'Login' });
};
const guest = (to, from, next) => {
  if (!store.getters.isLoggedIn) {
    next();
    return;
  }
  next({ name: 'academies' });
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('../views/layouts/home-layout.vue'),
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
    ],
  },
  {
    path: '/login',
    beforeEnter: guest,
    name: 'Login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/admin',
    component: () => import('../views/layouts/admin-layout.vue'),
    beforeEnter: auth,
    children: [
      {
        path: '/',
        redirect: 'academies',
      },
      {
        path: 'academies',
        name: 'academies',
        component: () => import('../views/admin/academies/academies.vue'),
      },
      {
        path: 'agency',
        name: 'agency',
        component: () => import('../views/admin/agency/agency.vue'),
      },
      {
        path: 'countries',
        name: 'countries',
        component: () => import('../views/admin/countries/countries.vue'),
      },
      {
        path: 'suppliers',
        name: 'suppliers',
        component: () => import('../views/admin/suppliers/suppliers.vue'),
      },
      {
        path: 'suppliers/new',
        name: 'newSupplier',
        component: () => import('../views/admin/suppliers/suppliersForm.vue'),
      },
      {
        path: 'suppliers/:id/details',
        name: 'supplierDetails',
        component: () => import('../views/admin/suppliers/suppliersForm.vue'),
      },
      {
        path: 'cities',
        name: 'cities',
        component: () => import('../views/admin/cities/cities.vue'),
      },
      {
        path: 'course_types',
        name: 'course_types',
        component: () => import('../views/admin/course_types/course_types.vue'),
      },
      {
        path: 'user_options',
        name: 'user_options',
        component: () => import('../views/admin/settings/settings.vue'),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

/**
 * Add Headers default
 */
router.beforeEach((to, from, next) => {
  const token = store.getters.getToken;

  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common.Authorization = undefined;
  }
  next();
});

export default router;
