<template>
  <div class="form-group">
    <select
      :value="value"
      class="form-control"
      :disabled="disabled"
      @input="emitInput"
      @change="emitChange"
    >
      <option value="">
        {{ label }}
      </option>
      <option
        v-for="opt in options"
        :key="opt.id"
        :value="opt.id"
      >
        {{ opt.name }}
      </option>
    </select>
    <label>
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String, Boolean],
      default: null,
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    label: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitChange({ target }) {
      this.$emit('change', target.value);
    },
    emitInput({ target }) {
      this.$emit('input', target.value);
    },
  },
};
</script>

<style>

</style>
