/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    agency_data: {},
  },
  mutations: {
    SET_AGENCY_DATA(state, data) {
      state.agency_data = data;
    },
  },
  actions: {
    getAgencyData({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/agency_data')
          .then((res) => {
            commit('SET_AGENCY_DATA', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_AGENCY_DATA', {});
            reject(err);
          });
      });
    },
    updateAgencyData({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.put('/agency_data', data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    agency_data(state) {
      return state.agency_data;
    },
  },
};
