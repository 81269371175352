/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('identity')) || null,
  },
  mutations: {
    AUTH_SUCCESS(state, { token, user }) {
      state.token = token;
      state.user = user;
    },
    LOGOUT(state) {
      state.token = null;
      state.user = null;
    },
  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios.post('/login', user)
          .then((res) => {
            // Set Token
            localStorage.setItem('token', res.data.data.token);

            // Set User
            localStorage.setItem('identity', JSON.stringify(res.data.data.userRes));

            commit('AUTH_SUCCESS', { token: res.data.data.token, user: res.data.data.userRes });
            resolve(res);
          })
          .catch((err) => {
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit('LOGOUT');
        localStorage.removeItem('token');
        localStorage.removeItem('identity');
        resolve();
      });
    },

    updateProfile({ commit }, form) {
      return new Promise((resolve, reject) => {
        axios.put('/profile', form)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
  getters: {
    getToken(state) {
      if (!state.token) return null;
      return state.token;
    },
    isLoggedIn(state, getters) {
      return getters.getToken != null;
    },
    getUser(state) {
      return state.user;
    },
  },
};
