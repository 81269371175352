<template>
  <v-date-picker
    v-model="date"
    mode="date"
    :popover="{ visibility: 'click' }"
    :masks="{
      input: format
    }"
    :disabled-dates="disabledDates"
    locale="es"
  >
    <template v-slot="{ inputValue, inputEvents }">
      <div class="form-group">
        <label>{{ label }}</label>
        <input
          class="form-control"
          :value="inputValue"
          v-on="inputEvents"
        >
      </div>
    </template>
  </v-date-picker>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, String, Date],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    format: {
      type: String,
      default: 'DD-MM-YYYY',
    },
    disabledDates: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    date: null,
  }),
  watch: {
    date(val) {
      this.$emit('input', val);
    },
    value: {
      immediate: true,
      handler(val) {
        this.date = val;
      },
    },
  },
};
</script>

<style scoped>
.calendar-container {
  position: absolute;
  top: 30px;
  left: 0;
}

</style>
