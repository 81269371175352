<template>
  <div class="form-group">
    <label>{{ label }}</label>
    <v-autocomplete
      ref="vautoc"
      v-model="result"
      :items="options"
      :get-label="getLabel"
      :component-item="template"
      :min-len="minLen"
      :input-attrs="{
        class: 'form-control',
      }"
      @item-selected="onSelected"
      @update-items="optionGetter"
    />
  </div>
</template>

<script>
import template from './Autocomplete/AcTemplate.vue';

export default {
  props: {
    value: {
      type: [Object],
      default: null,
    },
    icon: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    minLen: {
      type: Number,
      default: 3,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    optionGetter: {
      type: Function,
      default: () => ({}),
    },
    options: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      template,
      result: this.value,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.result = val;
      },
      deep: true,
    },
  },
  methods: {
    getLabel(item) {
      if (!item) return;
      // eslint-disable-next-line consistent-return
      return item.name;
    },
    // Executed when option is selected
    onSelected(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
    reset() {
      this.$refs.vautoc.searchText = null;
    },
  },
};
</script>
