/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    budgets: {},
  },
  mutations: {
    SET_BUDGETS(state, budgets) {
      state.budgets = budgets;
    },
  },
  actions: {
    createBudget({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.post('/budget', data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    showBudget({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`/budget/${token}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getBudgets({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/budget'.paginableUrl(params))
          .then((res) => {
            commit('SET_BUDGETS', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_BUDGETS', {});
            reject(err);
          });
      });
    },
    deleteBudget({ commit }, { token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/budget/${token}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    budget_options(state) {
      return state.budget_options;
    },
    budgets(state) {
      return state.budgets;
    },
  },
};
