import moment from 'moment';

function dateParser(object, format = null) {
  const dateReg = /^\d{4}([./-])\d{2}\1\d{2}$/; // regex for 'YYYY-MM-DD'
  const obj = object;

  if (!obj) return;

  if (format) {
    for (const a of Object.keys(obj)) {
      if (obj[a] instanceof moment) {
        obj[a] = obj[a].format(format);
      } else if (obj[a] instanceof Date) {
        obj[a] = moment(obj[a]).format(format);
      } else if (typeof obj[a] === 'object') {
        dateParser(obj[a], format);
      }
    }
  } else {
    for (const a of Object.keys(obj)) {
      if (typeof obj[a] === 'string' && obj[a].match(dateReg)) {
        obj[a] = moment(obj[a]).startOf('day'); // add .startOf('day') to obtain always Dates at 00:00 hs
      } else if (typeof obj[a] === 'object') {
        dateParser(obj[a]);
      }
    }
  }
}

export default dateParser;
