import Vue from 'vue';
import VueTables from 'vue-tables';
import VueModal from 'vue-modal';
import VueNoty from 'vuejs-noty';
import VueImageuploader from 'vue-imageuploader';
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';
import Autocomplete from 'v-autocomplete';
import VCalendar from 'v-calendar';
import App from './App.vue';
import router from './router';
import store from './store';
import mixins from './mixins/mixins';
import './assets/scss/bootstrap4/scss/bootstrap.scss';
import './assets/scss/fontawesome5/css/all.min.css';
import './assets/scss/main.scss';
import 'vue-tables/dist/vue-tables.css';
import 'vue-modal/dist/vue-modal.css';
import 'vuejs-noty/dist/vuejs-noty.css';
import './utils/paginable';
import './utils/objectFunctions';
import 'vue2-animate/dist/vue2-animate.min.css';
import 'v-autocomplete/dist/v-autocomplete.css';
import 'sweetalert2/dist/sweetalert2.min.css';

// Global request
window.axios = axios;
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.use(Autocomplete);
Vue.use(VueImageuploader);
Vue.use(VueSweetalert2);
Vue.use(VueTables);
Vue.use(VueModal);
Vue.use(VueNoty);
Vue.use(VCalendar);

// Global Mixins
Vue.mixin(mixins);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
