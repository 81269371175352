<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <span
      v-if="item"
      v-html="item.name"
    />
  </div>
</template>

<script>
export default {
  props: {
    // eslint-disable-next-line vue/require-prop-types
    item: { required: true },
    // eslint-disable-next-line vue/require-prop-types
    searchText: { required: true },
  },
};
</script>
