<template>
  <div>
    <div
      v-if="fromHome"
    >
      <div class="card my-3">
        <div class="card-body">
          <h4>Tus Detalles</h4>
          <p>Dejanos tus detalles para enviarte el presupuesto.</p>

          <div class="text-left">
            <form-input
              v-model="budget.full_name"
              label="Nombre Completo"
            />
            <form-input
              v-model="budget.email"
              label="Email"
            />
            <form-input
              v-model="budget.phone"
              type-number
              label="Telefono"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      class="card"
      :class="{
        'card-square': !fromHome
      }"
    >
      <div class="card-body">
        <h5>Curso</h5>
        <p v-if="fromHome">
          Empecemos eligiendo el curso que quieres.
        </p>

        <div
          class="text-left"
        >
          <form-select
            key="a"
            v-model="budget.location"
            label="Ciudad"
            :options="locations"
            @input="onSelectLocation"
          />
          <form-select
            v-if="!fromHome || currentStep >= 1"
            key="b"
            v-model="budget.course_type"
            label="Tipo de Curso"
            :options="courses"
            @change="onSetCourseType"
          />
          <form-select
            v-if="fromHome && currentStep >= 2"
            key="c"
            v-model="budget.nationality"
            label="Tu Nacionalidad"
            :options="nationalities"
            @change="getCourseDuration"
          />
          <form-select
            v-if="!fromHome || currentStep >= 3"
            key="d"
            v-model="budget.course_weeks"
            :options="weeksOptions"
            label="Semanas de Curso"
          />
          <form-select
            v-if="!fromHome"
            key="e"
            v-model="budget.class_time"
            label="Horario"
            :options="class_times"
            @change="nextStep(4)"
          />
          <form-date
            v-if="!fromHome || currentStep >= 3"
            key="calendar"
            v-model="budget.course_start_date"
            :disabled-dates="disabledDates"
            label="Fecha de Comienzo"
          />
          <form-checkbox
            v-if="!fromHome || currentStep >= 3"
            key="transfer"
            v-model="budget.with_transfer"
            label="Traslado aeropuerto"
          />
        </div>
      </div>
    </div>

    <div
      v-if="currentStep >= 1"
      :class="{
        'card-square': !fromHome,
        'mt-3': fromHome,
      }"
      class="card"
    >
      <div
        class="card-body"
        :class="{
          'pt-0': !fromHome,
        }"
      >
        <h5>Alojamiento</h5>
        <p v-if="fromHome">
          Necesitas alojamiento? Contanos los detalles
        </p>

        <div
          class="text-left"
        >
          <form-select
            key="g"
            v-model="budget.accommodation_type"
            label="Tipo de alojamiento"

            :options="accommodation_types_options"
            @change="onSetAccommodationType"
          />
          <form-select
            v-if="budget.accommodation_type !== 'none'"
            key="h"
            v-model="budget.accommodation_bathroom_type"
            label="Baño"

            :options="accommodation_bathroom_types_options"
            @change="onSetBathroomType"
          />
          <form-select
            v-if="budget.accommodation_type !== 'none'"
            key="i"
            v-model="budget.people_per_room"
            label="Personas x hab."

            :options="people_per_room_options"
            @change="nextStep(6)"
          />

          <form-select
            v-if="budget.accommodation_type !== 'none'"
            key="j"
            v-model="budget.accommodation_weeks"
            :options="accWeeksOptions"
            label="Semanas de alojamiento"
          />

          <FormSelect
            v-if="!fromHome"
            key="k"
            v-model="selOrder"
            label="Ordenar Academias por:"
            :options="orderOptions"
          />
        </div>
      </div>
    </div>

    <button
      v-if="fromHome && currentStep >= 3"
      class="btn btn-primary btn-block mt-3"
      @click="getResults"
    >
      Buscar Cursos
    </button>

    <button
      v-else-if="!fromHome"
      class="btn-block btn btn-success mb-5"
      style="border-radius: 0"
      @click="getResults"
    >
      Mostrar Resultados
    </button>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

import {
  FormSelect,
  FormCheckbox,
  FormInput,
  // FormAutocomplete,
  FormDate,
} from '@/components/inputs';

export default {
  components: {
    FormSelect,
    FormCheckbox,
    FormInput,
    // FormAutocomplete,
    FormDate,
  },
  props: {
    fromHome: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    disabledDates: { weekdays: [1, 3, 4, 5, 6, 7] },
    budget: {
      country_id: null,
      location: null,
      course_type: null,
      course_start_date: null,
      nationality: null,
      course_weeks: null,
      with_transfer: true,
      class_time: null,
      accommodation_type: 'none',
      accommodation_bathroom_type: null,
      people_per_room: null,
      accommodation_weeks: null,
      phone: null,
      full_name: 'null',
      email: null,
    },
    currentStep: 0,
    weeksOptions: [],
    accWeeksOptions: [],
    orderOptions: [
      { id: 'prices.week_price;desc', name: 'Mayor Precio primero' },
      { id: 'prices.week_price;asc', name: 'Menor Precio primero' },
    ],
    selOrder: 'prices.week_price;asc',
  }),
  computed: {
    ...mapGetters([
      'budgetData',
      'class_times',
      'locations',
      'nationalities',
      'courses',
      'accommodation_types_options',
      'accommodation_bathroom_types_options',
      'people_per_room_options',
    ]),
  },
  watch: {
    budget: {
      deep: true,
      handler(val) {
        this.SET_BUDGET_DATA(val);
      },
    },
  },
  mounted() {
    this.budget = this.budgetData;

    // set accommodation weeks options
    for (const a of Array(25).keys()) {
      this.accWeeksOptions.push({ id: a + 1, name: `${a + 1}` });
    }

    setTimeout(() => {
      if (!this.budget.location) this.getLocations();
    }, 200);
  },
  methods: {
    ...mapMutations([
      'SET_BUDGET_DATA',
    ]),
    ...mapActions([
      'getLocations',
      'getNationalities',
      'getCourses',
      'getCourseTimes',
      'getAccommodationTypesOptions',
      'getAccommodationBathroomTypesOptions',
      'getAccommodationPeopleOptions',
    ]),
    async onSelectLocation() {
      await this.getCourses();
      await this.getAccommodationTypesOptions();
      this.nextStep(1);
    },
    async onSetCourseType() {
      await this.getCourseTimes();
      await this.getNationalities();
      this.nextStep(2);
    },
    async onSetAccommodationType() {
      await this.getAccommodationBathroomTypesOptions();
    },
    async onSetBathroomType() {
      await this.getAccommodationPeopleOptions();
    },
    getNextMonday() {
      const dayINeed = 1; // for Monday
      const today = moment().isoWeekday();

      // if we haven't yet passed the day of the week that I need:
      if (today <= dayINeed) {
        // then just give me this week's instance of that day
        return moment().isoWeekday(dayINeed);
      }
      // otherwise, give me *next week's* instance of that same day
      return moment().add(1, 'weeks').isoWeekday(dayINeed);
    },
    getCourseDuration(val) {
      const options = [];
      this.budget.country_id = val.id;
      this.budget.course_start_date = this.getNextMonday().toDate();
      // eslint-disable-next-line eqeqeq
      const filteredNat = this.nationalities.filter((x) => x.id == val);
      const nationality = filteredNat.length ? filteredNat[0] : null;

      if (!nationality) {
        this.nextStep(2);
      } else {
        if (nationality.continent === 'Europe') {
          for (const a of Array(25).keys()) {
            options.push({ id: a + 1, name: `${a + 1}` });
          }
        } else {
          for (const a of Array(12).keys()) {
            options.push({ id: a + 1, name: `${a + 1}` });
          }
          options.push({ id: 25, name: '25 (Work and Study)' });
        }
        this.weeksOptions = options;
        this.nextStep(3);
      }
    },
    nextStep(step) {
      this.currentStep = step;
    },
    validate(rules = {}) {
      const validateEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
      };
      let valid = true;
      for (const attr of Object.keys(rules)) {
        if (!this.budget[attr]) {
          this.$noty.error(rules[attr]);
          valid = false;
        } else if (attr === 'email' && !validateEmail(this.budget[attr])) {
          this.$noty.error('The email is invalid');
          valid = false;
        }
      }
      return valid;
    },
    getResults() {
      let validationMsgs = {
        course_weeks: 'Seleccione Semanas de curso',
        course_type: 'Seleccione un Tipo de curso',
        nationality: 'Seleccione su Nacionalidad',
        class_time: 'Seleccione un horario de curso',
        location: 'Seleccione una Ciudad',
        full_name: 'Su Nombre completo es requerido',
        email: 'Su Email es requerido',
        phone: 'Su Telefono es requerido',
      };

      if (this.budget.accommodation_type !== 'none') {
        validationMsgs = {
          ...validationMsgs,
          accommodation_type: 'Seleccione un Tipo de Alojamiento',
          accommodation_bathroom_type: 'Seleccione un Tipo de Baño',
          people_per_room: 'Seleccione la capacidad maxima por habitacion',
          accommodation_weeks: 'Seleccione semanas de Alojamiento',
        };
      }
      if (this.validate(validationMsgs)) {
        this.$emit('onFinish', this.selOrder);
      }
    },
  },
};
</script>

<style scoped>
.card.card-square {
  border-radius: 0 !important;
  border: 0 !important;
}
</style>
