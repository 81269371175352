/* eslint-disable no-unused-vars */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-extend-native */

Object.defineProperty(Object.prototype, 'only', {
  value(fields) {
    const obj = {};
    fields.forEach((x) => {
      if (this[x] !== undefined) {
        obj[x] = this[x];
      }
    });

    return obj;
  },
});
Object.defineProperty(Object.prototype, 'except', {
  value(fields) {
    const obj = JSON.parse(JSON.stringify(this));

    fields.forEach((x) => {
      if (this[x] !== undefined) {
        delete obj[x];
      }
    });

    return obj;
  },
});
