/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    academies: {},
  },
  mutations: {
    SET_ACADEMIES(state, academies) {
      state.academies = academies;
    },
  },
  actions: {
    getAcademies({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get('/academies'.paginableUrl(params))
          .then((res) => {
            const result = res.data.data;
            result.data.map((x) => {
              const aux = x;
              aux.courses_qty = x.courses.length;
              aux.courses = aux.courses.map((y) => {
                const yx = y;
                yx.key = Math.random() * Date.now();
                yx.prices = yx.prices
                  .sort((a, b) => (a.from_weeks > b.from_weeks ? 1 : -1))
                  .map((c) => {
                    const xc = c;
                    xc.key = Math.random() * Date.now();
                    return xc;
                  });

                return yx;
              });
              return aux;
            });
            commit('SET_ACADEMIES', result);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_ACADEMIES', {});
            reject(err);
          });
      });
    },
    createAcademy({ commit }, academyData) {
      return new Promise((resolve, reject) => {
        axios.post('/academies', academyData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateAcademy({ commit }, academyData) {
      return new Promise((resolve, reject) => {
        axios.put(`/academies/${academyData.id}`, academyData.data)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteAcademy({ commit }, academyData) {
      return new Promise((resolve, reject) => {
        axios.delete(`/academies/${academyData.id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    academies(state) {
      return state.academies;
    },
  },
};
