<template>
  <div
    class="card mt-2"
  >
    <div class="card-body">
      <h4>Alojamientos</h4>

      <div class="row">
        <template
          v-for="a in course.academy.supplier.accommodation"
        >
          <div
            v-for="c in a.rooms"
            :key="c.id"
            class="col-12 col-sm-6 col-md-4 py-2"
          >
            <div
              class="accommodationItem"
              :class="{
                active: JSON.stringify(selAccommodation) === JSON.stringify({...a.except(['rooms']), room: c}),
              }"
              @click="selAccommodation = {...a.except(['rooms']), room: c}"
            >
              <h6><b>{{ a.type.name }}</b></h6>
              <p>Baño: {{ c.bathroom_type.name }}</p>
              <p>Capacidad: {{ c.max_people }}</p>
              <p>
                Precio:
                <span v-if="c.special_price">{{ moneyFormat(c.special_price.prices[0]) }}/semana</span>
                <span v-else>{{ moneyFormat(c.price_per_week) }}/semana</span>
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selAccommodation: null,
  }),
  watch: {
    selAccommodation(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped lang="scss">
.accommodationItem {
  text-align: center;
  border: 1px solid #ccc;
  border-radius: .25em;
  padding: 15px 8px 20px;
  transition: .5s ease;
  cursor: pointer;

  &:hover, &.active {
    transition: .5s ease;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
  }

  &.active {
    border: 2px solid #2276c9 !important;
    transition: .5s ease;
  }

  img {
    margin-bottom: 1em;
  }

  p {
    margin: 0;
    font-size: .9em;
  }
}
</style>
