import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions(['logout']),
    showError(error) {
      // console.log(error);

      switch (error.response.data.message) {
        case 'Token is invalid':
        case 'Token has expired':
          this.logout();
          this.$router.push({ name: 'Login' }).catch(() => {});
          break;

        case 'Unauthorized':
          this.$router.push({ name: 'Home' }).catch(() => {});
          break;
        default:
          break;
      }

      this.$noty.error(error.response.data.message);
    },
    fireConfirm(title, body) {
      return new Promise((resolve, reject) => {
        this.$swal.fire({
          title: title || 'Deleting Item. Are you sure?',
          text: body || "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
        }).then((result) => {
          if (result.isConfirmed) {
            resolve();
          } else {
            reject();
          }
        });
      });
    },

    moneyFormat(price) {
      if (typeof price === 'number') {
        return `€${price.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
      }
      return `€${parseFloat(price).toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,')}`;
    },
  },
};
