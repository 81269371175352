/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-vars */
export default {
  state: {
    accommodations: {},
    accommodationTypes: [],
    bathroomTypes: [],
  },
  mutations: {
    SET_ACCOMMODATIONS(state, data) {
      state.accommodations = data;
    },
    SET_ACCOMMODATION_TYPES(state, data) {
      state.accommodationTypes = data;
    },
    SET_BATHROOM_TYPES(state, data) {
      state.bathroomTypes = data;
    },
  },
  actions: {
    getAccommodations({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios.get(`/suppliers/${params.supplier_id}/accommodations`.paginableUrl(params))
          .then((res) => {
            const result = res.data.data;
            result.data.map((x) => {
              const aux = x;
              aux.rooms_qty = x.rooms.length;
              aux.type_name = x.type.name;
              aux.rooms = x.rooms.map((z) => ({
                ...z,
                show_special_price: z.special_price !== null,
                key: Math.random() * Date.now(),
              }));
              return aux;
            });
            commit('SET_ACCOMMODATIONS', result);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_ACCOMMODATIONS', {});
            reject(err);
          });
      });
    },
    getAccommodationTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/accommodations/types')
          .then((res) => {
            commit('SET_ACCOMMODATION_TYPES', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_ACCOMMODATION_TYPES', []);
            reject(err);
          });
      });
    },
    getBathroomTypes({ commit }) {
      return new Promise((resolve, reject) => {
        axios.get('/accommodations/bathroom_types')
          .then((res) => {
            commit('SET_BATHROOM_TYPES', res.data.data);
            resolve(res);
          })
          .catch((err) => {
            commit('SET_BATHROOM_TYPES', []);
            reject(err);
          });
      });
    },
    createAccommodation({ commit }, accommodationData) {
      return new Promise((resolve, reject) => {
        axios.post('/accommodations', accommodationData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    updateAccommodation({ commit }, accommodationData) {
      return new Promise((resolve, reject) => {
        axios.put(`/accommodations/${accommodationData.id}`, accommodationData)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteAccommodation({ commit }, accommodationData) {
      return new Promise((resolve, reject) => {
        axios.delete(`/accommodations/${accommodationData.id}`)
          .then((res) => {
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  getters: {
    accommodations(state) {
      return state.accommodations;
    },
    accommodationTypes(state) {
      return state.accommodationTypes;
    },
    bathroomTypes(state) {
      return state.bathroomTypes;
    },
  },
};
