<template>
  <div class="card">
    <div class="card-body">
      <h4>Academias</h4>

      <div class="row">
        <div
          v-for="c in budgetResults.academies"
          :key="c.id"
          class="col-12 col-sm-6 col-md-4 py-2"
        >
          <div
            class="academyItem"
            :class="{
              active: JSON.stringify(selCourse) === JSON.stringify(c),
            }"
            @click="selCourse = c"
          >
            <div
              class="image-container"
              :style="{'background-image': `url('${c.academy.logo.substr(0,4) !== 'http' ? `${apiURL}${c.academy.logo}` : c.academy.logo}')`}"
            />

            <h5><b>{{ c.academy.name }}</b></h5>
            <h6><b>{{ c.name }}</b></h6>
            <p>Horas Semanales: {{ c.week_hours }}</p>
            <p>Horario: {{ times[c.timetable] }}</p>
            <p v-if="!c.is_work_study">
              Precio: {{ moneyFormat(c.prices[0].week_price) }}/semana
            </p>
            <p v-else>
              Precio: {{ moneyFormat(c.prices[0].week_price) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    selCourse: null,
    apiURL: process.env.VUE_APP_API_URL,
    times: {
      morning: 'Mañana',
      'morning-afternoon': 'Mañana y Tarde',
      afternoon: 'Tarde',
      evening: 'Noche',
    },
  }),
  computed: {
    ...mapGetters([
      'budgetResults',
      'budgetData',
    ]),
  },
  watch: {
    selCourse(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style scoped lang="scss">
.academyItem {
  text-align: center;
  border: 2px solid #ccc;
  border-radius: .25em;
  padding: 15px 8px 30px;
  transition: .5s ease;
  cursor: pointer;

  &:hover, &.active {
    transition: .5s ease;
    -webkit-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.5);
  }

  &.active {
    border: 2px solid #2276c9 !important;
    transition: .5s ease;
  }

  img {
    margin-bottom: 1em;
  }

  p {
    margin: 0;
    font-size: .9em;
  }

  .image-container {
    background-size: contain;
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    display: inline-block;
    background-position: center center;
  }
}
</style>
