<template>
  <div>
    <div class="card bg-light mt-2">
      <div class="card-body">
        <small
          v-if="showMsg && lastSearch.budgetData.accommodation_type !== 'none'"
          class="text-danger text-center d-block mb-4"
        >
          <template v-if="!course">
            Selecciona una Academia para ver los alojamientos.
          </template>
          <template v-else>
            Selecciona un Alojamiento para ver el precio final.
          </template>
        </small>
        <small
          v-if="showMsg && lastSearch.budgetData.accommodation_type === 'none'"
          class="text-danger text-center d-block mb-4"
        >
          Selecciona una Academia para ver el precio final.
        </small>
        <table class="table-resume table">
          <tr v-if="course">
            <th>Curso</th>
            <td
              v-if="!course.is_work_study"
              style="width: 130px"
            >
              {{ moneyFormat(course.prices[0].week_price*lastSearch.budgetData.course_weeks) }}
            </td>
            <td
              v-else
              style="width: 130px"
            >
              {{ moneyFormat(course.prices[0].week_price) }}
            </td>
            <td
              style="width: 100px"
            >
              <small>{{ lastSearch.budgetData.course_weeks }} semanas</small>
            </td>
          </tr>
          <tr v-if="accommodation">
            <th>Alojamiento</th>
            <td
              v-if="!accommodation.room.special_price"
              style="width: 130px"
            >
              {{ moneyFormat(accommodation.room.price_per_week * lastSearch.budgetData.accommodation_weeks) }}
            </td>
            <td v-else>
              {{ moneyFormat(accommodation.room.special_price.price * lastSearch.budgetData.accommodation_weeks) }}
            </td>
            <td><small>{{ lastSearch.budgetData.accommodation_weeks }} semanas</small></td>
          </tr>
          <tr v-if="course && !course.is_work_study">
            <th>Enrolment Fee</th>
            <td style="width: 130px">
              {{ moneyFormat(course.academy.enrolment_fee) }}
            </td>
          </tr>
          <tr v-if="course && !course.is_work_study">
            <th>Book fee</th>
            <td style="width: 130px">
              {{ moneyFormat(course.academy.book_fee) }}
            </td>
          </tr>
          <tr v-if="course && !course.is_work_study">
            <th>Agency fee</th>
            <td style="width: 130px">
              {{ moneyFormat(budgetResults.agency_fee) }}
            </td>
          </tr>
          <tr v-if="lastSearch.budgetData.with_transfer && course">
            <th>Transfer Aeropuerto</th>
            <td style="width: 130px">
              {{ moneyFormat(course.academy.supplier.transfer_fee) }}
            </td>
          </tr>

          <tr class="total">
            <th>Total</th>
            <td style="width: 130px">
              {{ moneyFormat(total) }}
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div
      v-if="!showMsg"
      class="btn btn-primary mt-3 d-block"
      @click="sendBudget"
    >
      Envíame este Presupuesto
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    course: {
      type: Object,
      default: null,
    },
    accommodation: {
      type: Object,
      default: null,
    },
    lastSearch: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      'budgetResults',
    ]),
    showMsg() {
      if (this.lastSearch.budgetData.accommodation_type !== 'none') {
        return !this.course || !this.accommodation;
      }
      return !this.course;
    },
    total() {
      let total = 0;

      if (this.course) {
        if (this.course.is_work_study) {
          total += this.course.prices[0].week_price;
        } else {
          total += this.course.prices[0].week_price * this.lastSearch.budgetData.course_weeks;
          total += this.course.academy.enrolment_fee;
          total += this.budgetResults.agency_fee;
        }
        total += this.course.academy.book_fee;

        if (this.lastSearch.budgetData.with_transfer) {
          total += this.course.academy.supplier.transfer_fee;
        }
      }
      if (this.accommodation) {
        if (this.accommodation.room.special_price) {
          total += this.accommodation.room.special_price.price * this.lastSearch.budgetData.accommodation_weeks;
        } else {
          total += this.accommodation.room.price_per_week * this.lastSearch.budgetData.accommodation_weeks;
        }
      }

      return total;
    },
  },
  methods: {
    sendBudget() {
      this.$emit('onSendBudget');
    },
  },
};
</script>

<style scoped lang="scss">
table.table-resume.table {
  margin-bottom: 0;

  th {
    color: #2276c9;
    font-weight: 600;
    text-align: right;
  }

  td {
    text-align: right;
  }

  th, td {
    border: 0;
    padding: 0;
  }

  tr.total th, tr.total td {
    padding-top: .5em;
    font-size: 1.5em;
  }
}

.priceResume {
  color: #2276c9;
  font-weight: 600;
  padding: 0;
  margin:0 !important;
  font-size: .9em;

  small {
    font-weight: 600;
  }

  .total {
    font-size: 1.5em;
    padding-top: .5em;
  }
}
</style>
