<template>
  <div class="form-check">
    <input
      :id="'checkbox'+ikey"
      class="form-check-input"
      type="checkbox"
      :checked="value"
      @change="emitInput"
    >
    <label
      class="form-check-label"
      :for="'checkbox'+ikey"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, Boolean],
      default: null,
    },
    typeNumber: {
      type: Boolean,
      default: false,
    },
    ikey: {
      type: Number,
      default: () => Math.random() * Date.now(),
    },
    label: {
      type: String,
      default: null,
    },
  },
  methods: {
    emitInput(val) {
      this.$emit('input', val.target.checked);
    },
  },
};
</script>

<style>

</style>
